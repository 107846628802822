import { createApp } from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import VueGtag from "vue-gtag"

createApp(App).use(vuetify).
use(VueGtag,{
  config: {
    //id: "UA-35500247-6",
    id: "G-8X8TVJB91C",
    params: {
      anonymize_ip: true
    }
  }
}).mount('#app');
