<template>
  <v-app>
    <v-main>
      <PlayerEol/>
    </v-main>
  </v-app>
</template>

<script>
import PlayerEol from './components/PlayerEol.vue'
import { event } from 'vue-gtag'
import { pageview } from 'vue-gtag'

export default {
  name: 'App',

  components: {
    PlayerEol,
  },
  methods : {
    buttonClicked() {
      event('earthtv-ref-click', {
        'event_category': 'to-earthtv',
        'event_label': 'Click link to earthtv',
        'value': 1
      })
    },
    track () {
      pageview('player')
    }
  },

  // data: () => ({
  //   //
  // })
}
</script>
